import React from 'react'
import HomeDemo2 from './Forked/HomeDemo2'
import Roadmap from './Forked/HomeDemo2/SecOurRoadmap'
import SecPartners from './Forked/HomeDemo2/SecPartners'
import WelcomeArea from './Forked/HomeDemo2/SecWelcomeArea'
import SecOurServices from './Forked/HomeDemo2/SecWelcomeArea/SecOurServices'
import Team from './Forked/HomeDemo2/SecOurTeam'

import VerticalBar from './Forked/HomeDemo2/VerticalBar/VertricalLeftBar'

import Subscribe from './Forked/HomeDemo2/Subscribe'
import FooterContent from './Forked/HomeDemo2/FooterContent'
import HorizontalCenter from './Forked/HomeDemo2/VerticalBar/HorizontalCenter'



function App() {
  return (
    <div className='
      text-white h-screen w-screen overflow-x-hidden font-FutureNow  bg-main-bg bg-cover 
      '>

      
          {/* <div className='h-full w-full -z-[100] bg-repeat sticky'><img src={sea} alt='seabg' /></div> */}
          <div id='Home'>

            <HomeDemo2 />
            <WelcomeArea />
            {/*<HorizontalCenter />*/}
            {/*<VerticalBar />*/}
            
            <SecPartners />
            
            <SecOurServices />
            <Roadmap />
            <Team />
            
            <Subscribe />
            <FooterContent />
            
          </div>
    </div>
  )
}

export default App