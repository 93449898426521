import React from 'react'
import Header from './Header'




const HomeDemo2 =() =>{
    
    return(
        <div className='z-[100]'>
            <Header />
            
        </div>
        
    )
}




export default HomeDemo2