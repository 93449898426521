import React from 'react'
import shark7 from '../../../assets/img/team-img/shark-7.png'
import shark2 from '../../../assets/img/team-img/shark-2.png'
import shark3 from '../../../assets/img/team-img/shark-3.png'
import shark4 from '../../../assets/img/team-img/shark-4.png'
import shark5 from '../../../assets/img/team-img/shark-5.png'
import shark6 from '../../../assets/img/team-img/shark-6.png'




function Team() {
  return (
    <div id='Team' className='my-16 transition duration-700  hover:translate-y-10 hover:scale-100'>
        <div className="text-center font-bold text-yellow-500 text-6xl 3xl:text-[200px]">....</div>
        <h1 className='text-3xl md:text-4xl lg:text-5xl xl:text-6xl 3xl:text-9xl font-bold text-center text-cyan-400'>
            Introducing the Team
        </h1>

        <div className=' mx-auto flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 md:col-span-1 md:row-span-2 content-around'>
            <div className='text-center border  w-4/6 mx-auto my-3 border-yellow-500 rounded-lg backdrop-blur-sm'>
                <img src={shark2} alt='' />
                <h1 className='text-yellow-400 font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl 3xl:text-7xl'>Logan Hailele, CEO</h1>
                <p className='text-cyan-300 p-3 text-center text-lg md:text-xl xl:text-3xl 3xl:text-5xl  '> With his innovative thinking and extensive background in team
                development, Logan looks to explore new boundaries and discover better ways for end users to control their investments. Using a sharp eye for talent and attention to detail, Logan has built a team of likeminded individuals that help bring this vision to life.
                </p>
                
            </div>
            <div className='text-center border  w-4/6 mx-auto my-3 border-yellow-500 rounded-lg backdrop-blur-sm'>
                <img src={shark3} alt='' />
                <h1 className='text-yellow-400 font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl 3xl:text-7xl'>Cameron Murray, COO</h1>
                <p className='text-cyan-300 p-3 text-center text-lg md:text-xl xl:text-3xl 3xl:text-5xl  '>Cameron has multiple years in business and customer relations, with a keen interest in upper management and startups. He has a proven record and is like a “Swiss Army Knife” when it comes to getting the job done. His dedication and leadership capabilities motivate all others on the team to meet any challenge.

                </p>
            </div>
            <div className='text-center border  w-4/6 mx-auto my-3 border-yellow-500 rounded-lg backdrop-blur-sm'>
                <img src={shark6} alt='' />
                <h1 className='text-yellow-400 font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl 3xl:text-7xl'>Zachary Popowcer, CAO</h1>
                <p className='text-cyan-300 p-3 text-center text-lg md:text-xl xl:text-3xl 3xl:text-5xl  '> Utilizing a bachelor’s degree in criminal justice from the University of Nevada, Las Vegas, experience with copywriting, and thorough legal research capabilities, Zachary keeps Loan Shark Solutions, Inc. on the right track administratively.
                </p>
            </div>
            <div className='text-center border  w-4/6 mx-auto my-3 border-yellow-500 rounded-lg backdrop-blur-sm'>
                <img src={shark4} alt='' />
               <h1 className='text-yellow-400 font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl 3xl:text-7xl'>Daniel Gammal, CTO</h1>
               <p className='text-cyan-300 p-3 text-center text-lg md:text-xl xl:text-3xl 3xl:text-5xl  '>Amassing 15 years in software development, project leadership, building programs from the ground up, and maintaining high traffic websites, Daniel’s skills push the boundaries of internet technology to the next level.
                </p>
            </div>
            {/*<div className='text-center border  w-4/6 mx-auto my-3 border-yellow-500 rounded-lg backdrop-blur-sm'>*/}
            {/*    <img src={shark5} alt='' />*/}
            {/*    <h1 className='text-yellow-400 font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl 3xl:text-7xl'>Jason Sablan, Marketing</h1>*/}
            {/*    <p className='text-cyan-300 p-3 text-center text-lg md:text-xl xl:text-3xl 3xl:text-5xl  '>Jason can only be described as motivated and dedicated. With his vast range of experiences from military service, a technical, and mechanical background, he provides unique marketing ideas in addition to perspectives that push the team to succeed in all tasks. </p>*/}
            {/*</div>*/}
            {/*<div className='text-center border  w-4/6 mx-auto my-3 border-yellow-500 rounded-lg backdrop-blur-sm'>*/}
            {/*    <img src={shark7} alt='' />*/}
            {/*    <h1 className='text-yellow-400 font-bold text-xl md:text-3xl lg:text-3xl xl:text-4xl 3xl:text-7xl'>James Jordan, Marketing</h1>*/}
            {/*    <p className='text-cyan-300 p-3 text-center text-lg md:text-xl xl:text-3xl 3xl:text-5xl  '> With his attention to detail, and extensive background in both marketing and development, James utilizes his unique skills, talents, and experience by bringing Loan Shark Solutions, Inc. to the highest stage possible.</p>*/}
            {/*</div>*/}
        </div>
    </div>
  )
}

export default Team