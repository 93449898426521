import React from 'react'


function Roadmap() {
  return (
    <div id='Timeline' className='my-28 mx-auto text-yellow-400   transition duration-700  hover:translate-y-10 hover:scale-110 3xl:hover:scale-125 3xl:my-80 '>
      <div className="text-center font-bold text-yellow-500 text-6xl 3xl:text-[200px]">...</div>
        <div className=''>
            <h1 className='font-FutureNow text-3xl md:text-4xl lg:text-5xl xl:text-6xl 3xl:text-9xl font-bold text-center text-cyan-400'>Timeline</h1>
        </div>
    
        <Timeline />
    </div>
    
    
  )
}

export default Roadmap





const Timeline =() =>{




  return(
    <div  className='antialiased p-5 md:w-2/3 mx-auto xl:w-2/4'>
      <div className='container mx-auto flex flex-row justify-evenly'>
        <div className='flex flex-col justify-around '>
          
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q2 2021</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q3 2021</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q4 2021</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q1 2022</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q2 2022</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q3 2022</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q4 2022</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q1 2023</div>
          <div className='w-20 3xl:w-36 3xl:h-16  text-cyan-300 border-2 3xl:border-4 border-cyan-300 rounded-lg text-center 3xl:text-4xl 3xl:pt-2'>Q2 2023</div>
        </div>
        <div className='w-3 3xl:w-6 mx-auto h-[1500px] 3xl:h-[1900px] border-[3px] rounded-lg shadow-lg shadow-cyan-300 border-cyan-300 bg-yellow-400  flex flex-col justify-around relative '>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
          <div className='w-4 h-4 3xl:w-7 3xl:h-7  -mx-1   bg-yellow-300 border-4 3xl:border-8 border-black   shadow-md shadow-cyan-300 rounded-full '></div>
        </div>
        <div className='flex flex-col justify-around'>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Creation of Core Idea
              </li>
              <li>Assembled the Team</li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Shark Token Genesis Event
              </li>
              <li>
              Established Loan Shark Solutions Incorporated
              </li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              First Draft of White Paper

              </li>
              <li>
              Concept of Platform Complete
              </li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Website Creation Began
              </li>
              <li>
              Internal Testing of Product
              </li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Established First Partnerships
              </li>
              <li>White Paper Finalized</li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Public Website Launch
              </li>
              <li> Public Shark Token Presale</li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Further MVP Development
              </li>
              <li>
              Expand Partnerships
              </li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Private Testing of Platform
              </li>
              <li>Open Virtual Auction House </li>

            </ul>
          </div>
          <div>
            <ul className='list-disc w-24 3xl:w-44 3xl:text-3xl'>
              <li>
              Public MVP Release
              </li>
              <li>Expand Platform Capabilities</li>

            </ul>
          </div>
        </div>
        
      </div>

    </div>
  )
}