import React from 'react'

function FooterContent() {
  return (
    <div className='flex flex-col lg:flex-row justify-around '>
        
        <div className='text-center my-10 '>
            <h1 className='text-cyan-300 font-bold text-center 3xl:text-7xl'>Privacy & TOS</h1>
            <div className='text-yellow-500  font-semibold'>
                <a href='https://docs.google.com/document/d/1-c2K0kCXaZDAWqNaoRmXdQp2ouTme5f1/edit?usp=sharing&ouid=111309491762129931333&rtpof=true&sd=true'>
                    <h1 className='hover:text-yellow-400 3xl:text-6xl'>Terms and Conditions</h1></a>
                <a href='https://docs.google.com/document/d/1-fhWNEU-0tBNsP7HCw1jNtAQ35DxqLYU/edit?usp=sharing&ouid=111309491762129931333&rtpof=true&sd=true'>
                    <h1 className='hover:text-yellow-400 3xl:text-6xl'>Privacy Policy</h1></a>
                <a href='https://docs.google.com/document/d/12HNCbujOWvMUhqPE9HbdGczMtW7HQuAc/edit?usp=sharing&ouid=111309491762129931333&rtpof=true&sd=true'>
                    <h1 className='hover:text-yellow-400 3xl:text-6xl'>Disclaimer</h1></a>
            </div>
        </div>
        <div className='text-center my-10'>
            <h1 className='text-cyan-300 font-bold 3xl:text-7xl '>Contact</h1>

            <p className='w-72 3xl:w-[1200px] lg:w-80 mx-auto text-yellow-500 hover:text-yellow-400 font-semibold 3xl:text-6xl'>
            Loan Shark Solutions, Inc. <br/>
            9750 W Skye Canyon DR STE 160-115 <br />
            Las Vegas, Nevada 89166
            <br/>
        support@loansharksolutions.com


            </p>
        </div>
    </div>
  )
}

export default FooterContent