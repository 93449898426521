
import React from 'react'
import metadock from '../../../assets/img/partners/metadock.png'
import studiostargazer from '../../Assets/StudioStargazer.png'

function SecPartners() {
  return (
    <div id='Partners' className='mt-10 text-yellow-400  transition duration-700  hover:translate-y-10 hover:scale-110'>
        <div className='text-3xl text-center font-bold my-4 '>
          <h1 className='font-extrabold text-6xl 3xl:text-[200px]'>.</h1>
            <h1 className='text-cyan-400 text-3xl md:text-4xl lg:text-5xl xl:text-6xl 3xl:text-9xl font-bold text-center'>Our Partners</h1>

        </div>

        <div className='border-2 border-yellow-500 p-5 w-4/5 mx-auto backdrop-blur-sm  rounded-lg'>
            <a className='flex flex-row justify-center relative ' href='https://www.metadock.net/'><img className='w-1/8 3xl:w-1/6' src={metadock} /></a>
            
            <a href='https://www.metadock.net/' className='z-20'
            ><h1 className='text-center text-3xl md:text-3xl lg:text-4xl xl:text-6xl 3xl:text-9xl my-3  text-white font-medium font-Poppins'>MetaDock</h1></a>
               <a href='https://www.metadock.net/'><p className='w-3/4 mx-auto text-center font-Poppins z-30 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-6xl  '>
                MetaDock is a split-screen productivity app that lets you dock multiple browser windows
                vertically and horizontally in your layout as you see fit, making perfect use of your monitor real estate.
            </p></a>
        </div>
        <div className='border-2 border-yellow-500 p-5 w-4/5 mx-auto backdrop-blur-sm  rounded-lg my-10'>
            <a className='flex flex-row justify-center relative ' href='https://www.studiostargazer.org/'><img className='w-1/8 3xl:w-4/5' src={studiostargazer} /></a>
            
            
               <a href='https://www.studiostargazer.org/'><p className='w-3/4 mx-auto text-center font-Poppins z-30 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-6xl '>
               Studio Stargazer is a production company with their eyes set to the entire universe of entertainment. While offering media creation services, they also masterfully create their own podcasts, music, video effects, theater productions, and even gaming livestreams.


            </p></a>
        </div>



    </div>
  )
}

export default SecPartners