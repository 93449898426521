import React from 'react'

export default function Subscribe() {
  return (
    <div className='my-56 border w-5/6 lg:w-3/4 xl:w-2/3 3xl:h-4/5 3xl:py-40 mx-auto border-yellow-500 rounded-lg h-64 backdrop-blur-sm'>
        <div>
            <h1 className='font-shark text-yellow-500 text-center text-3xl md:text-4xl lg:text-5xl  xl:text-6xl mt-5 3xl:text-9xl'> LOAN SHARK</h1>
            <h1 className=' text-center text-xl text-cyan-300 mt-6 3xl:text-6xl'>Mailing List</h1>
            <a className='mx-auto flex flex-row justify-center mt-6 bg-blue-900 w-40 3xl:w-72 3xl:h-20 border-2  shadow-xl transition hover:translate-y-3 duration-200 ease-out
            3xl:text-6xl 
            'href='https://loansharksolutions.us13.list-manage.com/subscribe/post?u=d3ee948febc0ac0e8e502f13e&id=c87e8c8f98
            '
            >
                <button className=''>
                    Subscribe
                </button>
            </a>

        </div>

    </div>
  )
}

