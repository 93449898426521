import React from 'react'


function SecOurServices({img,title,text}) {
  return (
    <div  className='py-10 my-10 transition duration-700  hover:translate-y-10 hover:scale-110'  id='Mission'>
        <div className='hover:backdrop-blur-sm'>
            <div className='text-center font-bold text-yellow-500 text-6xl 3xl:text-[200px]'>..</div>
            <h1 className='text-center  text-3xl md:text-4xl lg:text-5xl xl:text-6xl 3xl:text-9xl font-bold text-center my-3 text-cyan-400'>
            Mission Statement
            </h1>
            <p className='text-lg text-center w-3/4 mx-auto text-yellow-500   md:text-3xl xl:text-3xl 3xl:text-6xl'>
            Loan Shark Solutions, Inc. will provide a global bridge network between lenders and borrowers to exchange NFTs for capital, quickly and easily.            </p>

        </div>
        <SecOperations />
       
    </div>
  )
}

export default SecOurServices





const SecOperations =()=>{
    return(
        <div className='my-12'>
            <div className='border-2 border-yellow-500 w-4/5 lg:w-3/4  xl:w-2/3  mx-auto rounded-lg my-10 backdrop-blur-sm'>

                 {/* <div><img /></div> */}
                 <h5 className=' text-center underline my-3 text-3xl text-cyan-400 3xl:text-7xl'>NFT Loans </h5>
                 <p className='text-center w-4/5  mx-auto text-yellow-500 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-5xl  '>Users will have the ability to create peer-to-peer loans and will be collateralized using their own digital assets. Our platform will be a one-stop-shop with a simple user interface, multiple inter-changeable options, and all dependent on the user’s individual needs.
                 </p>

            </div>
            <div className='border-2 border-yellow-500 w-4/5 lg:w-3/4  xl:w-2/3  mx-auto rounded-lg my-10 backdrop-blur-sm'>
                 {/* <div><img /></div> */}
                 <h5 className=' text-center underline my-3 text-3xl  text-cyan-400 3xl:text-7xl'>Smart Contracts</h5>
                 <p className='text-center w-4/5  mx-auto text-yellow-500 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-5xl   '> Custom tailored, in-house smart contracts will allow Loan Shark Solutions to facilitate all transactions on the platform with terms set by, and agreed by all parties. The Stellar based Shark Token is the backbone of this system, and will satisfy all payments in our ecosystem.

                 </p>

            </div>
            <div className='border-2 border-yellow-500 w-4/5 lg:w-3/4  xl:w-2/3  mx-auto rounded-lg my-10 backdrop-blur-sm'>
                 {/* <div><img /></div> */}
                 <h5 className=' text-center underline my-3 text-3xl text-cyan-400 3xl:text-7xl'>Virtual Auction House</h5>
                 <p className='text-center w-4/5  mx-auto text-yellow-500 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-5xl  '>Our auction house for loaned and defaulted NFTs exists strictly in the metaverse, and will be easily accessible to everyone no matter where they are on the planet.


                 </p>

            </div>
            <div className='border-2 border-yellow-500 w-4/5 lg:w-3/4  xl:w-2/3  mx-auto rounded-lg my-10 backdrop-blur-sm'>
                 {/* <div><img /></div> */}
                 <h5 className=' text-center underline my-3 text-3xl  text-cyan-400 3xl:text-7xl'>Social Events</h5>
                 <p className='text-center w-4/5  mx-auto text-yellow-500 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-5xl '>Featuring live bands, comedy shows, and talented artists of all varieties, Loan Shark Solutions will create a lively space for people all around the globe to interact with, and experience culture in an exciting new way.


                 </p>

            </div>
            <div className='border-2 border-yellow-500 w-4/5 lg:w-3/4  xl:w-2/3  mx-auto rounded-lg my-10 backdrop-blur-sm'>
                 {/* <div><img /></div> */}
                 <h5 className=' text-center underline my-3 text-3xl  text-cyan-400 3xl:text-7xl'>NFT Tattoos & Art Gallery</h5>
                 <p className='text-center w-4/5  mx-auto text-yellow-500 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-5xl '>A real-world work of art with blockchain certification! Loan Shark Solutions will establish a gallery to browse and purchase one-of-a-kind masterpieces. A user's tattoo artist of choice will then be able to easily access the art to stencil, and tattoo directly onto the user.


                 </p>

            </div>
            <div className='border-2 border-yellow-500 w-4/5 lg:w-3/4  xl:w-2/3   mx-auto rounded-lg my-10 backdrop-blur-sm'>
                 {/* <div><img /></div> */}
                 <h5 className=' text-center underline my-3 text-3xl  text-cyan-400 3xl:text-7xl'>The Loan Shark Solutions Store</h5>
                 <p className='text-center w-4/5  mx-auto text-yellow-500 text-base md:text-lg lg:text-xl xl:text-3xl 3xl:text-5xl '>As well as providing a great place to aggregate your transactions, Loan Shark Solutions will be minting our very own NFTs. From concert tickets, to unique artwork, music, videos, and more, all will be backed up with the network of technology that is driving the future of finance.


                 </p>

            </div>
        </div>
    )
}