import {useEffect,useState} from 'react'

import {Logo} from '../../data/data-layout/data-Header.js';

import {Addshrink , addActiveClass , OpenMenu , moveSmooth} from "../../utils/"

import { faBars,faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import './header.css'

// import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
      Addshrink()
  },[])

  useEffect(() => {
      OpenMenu()
  },[])

  useEffect(() => {
      moveSmooth()
  },[])

  return (
    <div>
      {/* <Preloader /> */}
      <header className="fixed z-[5000] top-0 w-full backdrop-blur-3xl 
       h-24 lg:h-28 3xl:h-44 hover:transition duration-150 
      shadow-md font-FutureNow
      " >
        <div className="">
          <div className="">
            <nav className="" id="">
              {/* <a className="hidden" href="#"><img src={Logo} alt="logo" /></a> */}
              <div className="">
                <span className="navbarToggler" onClick={addActiveClass}>
                    <span />
                    <span />
                    <span />
                </span>
              </div>
              <div className="classy-menu">
                {/*<div className="classycloseIcon">*/}
                {/*  <div className="cross-wrap" onClick={addActiveClass}>*/}
                {/*      <span className="top" />*/}
                {/*      <span className="bottom" />*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/* <div className=" hidden"> */}
                  {/* <ul id="nav">
                    <li><a onClick={moveSmooth} href="#home">Home</a></li>
                    <li><a onClick={moveSmooth} href="#partners">Partners</a></li>
                    <li><a onClick={moveSmooth} href="#mission">Mission</a></li>
                    <li><a onClick={moveSmooth} href="#timeline">Timeline</a></li>
                    <li><a onClick={moveSmooth} href="#team">Team</a></li>
                      <li><a data-toggle="modal" data-target="#tokenomics-modal">Tokenomics</a></li>
                      <li><a data-toggle="modal" data-target="#coming-soon-modal">WalletConnect</a></li>
                    <li><a href="https://drive.google.com/file/d/10PMMqyjmPV1ivwhO0JHW3ROglV5OzNb0/view" target="_blank">White paper</a></li>
                  </ul> */}
                {/* </div> */}
              </div>
            </nav>
          </div>
        </div>
        <NewHeader />
      </header>
    </div>
  );
}

export default Header;


// rewriting new component 

const NewHeader = () =>{
  useEffect(() => {
    Addshrink()
},[])

useEffect(() => {
    OpenMenu()
},[])

useEffect(() => {
    moveSmooth()
},[])

  


  return(
    <div className='py-0 px-3 border-b-transparent'>

      <div className='flex flex-row lg:justify-around justify-between w-full '>
        <a  href='https://app.hellosign.com/s/IHM8w6kX'><img
        className='w-24 h-24  md:w-28 md:h-28 lg:w-32 lg:h-32 xl:w-36 xl:h-36 3xl:h-48 3xl:w-48 '
         src={Logo} 
         alt='loanshark'  /></a>
        <div className='text-3xl mt-8 lg:hidden'><MenuButton /></div>
        <div className='hidden lg:visible lg:flex lg:flex-row  w-[1100px] xl:w-[1000px] 3xl:w-[1300px] justify-end mr-20 mt-6   lg:mt-9 xl:mt-10 3xl:m-20'>
          <ul className=''>
            <a href='#Home' className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400  hover:font-bold  '>Home</a>
            <a href='#Partners' className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400  hover:font-bold'>Partners</a>
            <a href='#Mission' className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400  hover:font-bold '>Mission</a>
            <a href='#Timeline' className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400  hover:font-bold '>Timeline</a>
            <a href='#Team' className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400  hover:font-bold'>Team</a>
            {/*<a className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400  hover:font-bold'>WalletConnect</a>*/}
            
            <a href='https://docs.google.com/document/d/1-bIB7Pp410eu5y78CJTMECs4T35kne0c/edit?usp=sharing&ouid=111309491762129931333&rtpof=true&sd=true'
            className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400 hover:font-bold'>White Paper</a>
           
           <a href={'https://app.hellosign.com/s/IHM8w6kX'} className='mx-2 text-xl xl:text-xl 3xl:text-4xl hover:text-cyan-400 hover:font-bold'>Buy $SHARK</a>
           
           
           
          
          </ul>
          
        </div>
      </div>
    </div>
  )
}


const MenuButton =() =>{
  const [menu,setMenu] = useState(false)
  
  const menuHandler =() =>{
    setMenu(!menu)
    
  }


  if(menu === false){
    return(
      <div className=''>
        <div><button onClick={menuHandler}><FontAwesomeIcon icon={faBars} /></button></div>
        
      </div>
    )
  }
  else{
    return(
      <div className=''>
        <div><button onClick={menuHandler}><FontAwesomeIcon icon={faXmark} /></button></div>
        <Sidebar />
        
      </div>
    )
  }




  

  
}



const Sidebar =()=>{
  return(
    <div className='z-[100]  bg-slate-600  rounded-lg w-60  right-10 bg-opacity-95   absolute mt-8 '>
      <div>
      <ul className=' flex flex-col justify-between text-center'>
            <a href='#Home' className='my-2 hover:font-bold text-cyan-400'>Home</a>
            <a href='#Partners' className='my-2  hover:font-bold text-cyan-400'>Partners</a>
            <a href='#Mission' className='my-2 hover:font-bold text-cyan-400'>Mission</a>
            <a href='#Timeline' className='my-2 hover:font-bold text-cyan-400 '>Timeline</a>
            <a href='#Team' className='my-2 hover:font-bold text-cyan-400'>Team</a>
            {/*<a className='my-2 hover:font-bold text-cyan-400'>WalletConnect</a>*/}
            
            < a href='https://docs.google.com/document/d/1-bIB7Pp410eu5y78CJTMECs4T35kne0c/edit?usp=sharing&ouid=111309491762129931333&rtpof=true&sd=true'
             className='my-2 hover:font-bold text-cyan-400'>White Paper</a>

          <a href={'https://app.hellosign.com/s/IHM8w6kX'} className='my-2 hover:font-bold text-cyan-400'>Buy $SHARK</a>




      </ul>
      </div>
    </div>
  )
}