// welcome component for the website 


import React from 'react'
import SecWelcomeContent from './SecWelcomeContent'

function WelcomeArea() {
  return (
    <div className='mt-40'>
        <div>
            <SecWelcomeContent />
        </div>
    </div>
  )
}

export default WelcomeArea