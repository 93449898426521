import React from 'react'

function SecWelcomeContent() {
  return (
    <div >
        <div className='w-5/6 mx-auto 3xl:mt-48'>
          <div className='backdrop-blur-sm border-2 border-yellow-400 py-5 text-center rounded-lg '>
              <h1 className='max-h-[992px] text-yellow-500  text-center font-shark text-3xl md:text-4xl lg:text-5xl xl:text-6xl 3xl:text-9xl  '><b>$</b>Shark</h1>
              <p className='w-3/4 mx-auto my-3  text-lg md:text-xl lg:text-3xl text-cyan-300 3xl:text-5xl '>
                Loan Shark Solutions aims to be the go-to platform for NFT lending, borrowing, entertainment, and more. We are focused on empowering users on our peer-to-peer network in which each party gets to set, and agree to, terms and conditions surrounding NFT lending and rentals. This will all be possible with the power of our Stellar based utility token, $SHARK, and in-house smart contracts designed by "YOU." Our goal is to unlock and recycle funds through digital assets that would have otherwise sat and collected dust in wallets, while also providing the ultimate ecosystem for investors and enthusiasts alike. Conduct business on your terms, be in charge of your digital assets, be your own Loan Shark!

              </p>
              <p className=' text-center text-cyan-300 text-lg md:text-xl xl:text-3xl font-bold 3xl:text-5xl' >Built on Stellar, Created for All.</p>
          </div>

            <video className={"mt-10"} src={"video.mp4"} width={"100%"} height={"100%"} controls={true} />
        </div>
    </div>
  )
}

export default SecWelcomeContent